import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

/* -------------------------------------------------------------------------- */
/*                               Get Alumnos                              */
/* -------------------------------------------------------------------------- */
export async function getAlumnos({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosProductosSeguimiento({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_productos_seguimiento',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

}
export async function getIndicadoresVentas({ usuario_id, nombre_indicadores }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("nombre_indicadores", nombre_indicadores);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/indiocadores_ventas',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

}
export async function getPlanificacionLlamadas({ usuario_id, nombre_dato }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("nombre_dato", nombre_dato);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/planificacion_llamadas',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

}
export async function setGestionarEnOrigen({ usuario_id, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumno_gestionar_en_origen',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

}
export async function updateAlumnoPrincipalId({ usuario_id, alumno_id, alumno_principal_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("alumno_principal_id", alumno_principal_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/update_alumno_principal_id',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

}
export async function recomendarAlumnoPrincipal({ usuario_id, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/recomendar_alumno_principal',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

}
export async function getAlumnosNotasExamenes({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_notas_examenes',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

}
export async function updateAlumnoNotasExamenesPlaza({ alumno_id, plaza }) {
    try {
        let formData = new FormData();

        formData.append("alumno_id", alumno_id);
        formData.append("plaza", plaza);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_notas_examenes_update_plaza',
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231",
                // 'X-API-KEY': "1231231231231231"
            },
            // crossorigin: true,    
            // mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        console.log(error)
        return undefined;
        // throw error;
    }

}
/* -------------------------------------------------------------------------- */
/*                               Get Alumnos                              */
/* -------------------------------------------------------------------------- */
export async function getAlumnosSinAsignar({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_sin_asignar',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getUsuariosCreadores({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/usuarios_creadores',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getUsuariosFacturas({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/usuarios_facturas',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getCreador({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("creador_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/usuarios_creadores',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}

export async function getUsuarioFactura({ usuario_id, factura_id }) {
    try {
        let formData = new FormData();

        formData.append("factura_id", factura_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/usuarios_facturas',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosPivot({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_pivot',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getVentasComercial({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/ventas_comercial',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosRegistrados({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_registrados',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosInteraccion({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_interaccion',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosTuteaLastLogin({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutea_last_login',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosRegistradosComunidad({ usuario_id, comunidad_autonoma_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("comunidad_autonoma_id", comunidad_autonoma_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_registrados_comunidad',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosRegistradosBuscar({ usuario_id, telefono, correo_electronico }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("telefono", telefono);
        formData.append("correo_electronico", correo_electronico);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_registrados_buscar',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosBaja({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_baja',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosPosibleBaja({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_posible_baja',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getTutoresGestion({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutores_gestion',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getRingoverLlamadas({ usuario_id, fecha }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("fecha", fecha);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/ringover_llamadas',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getEstadisticas({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/ringover_estadisticas',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getExposicionesOrales({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/exposiciones_orales',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getTags({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tags_list',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function insertar_tag({ usuario_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tag", (campos.tag))
        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tags_insert',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function insertar_alumno_tag({ usuario_id, alumno_id, tag_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tag_id", (tag_id))
        formData.append("alumno_id", (alumno_id))
        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tag_alumno_insert',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function deshabilitar_tag({ usuario_id, tag_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tag_id", tag_id)
        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/tags_deshabilitar',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function alumno_tag_eliminar({ usuario_id, alumno_id, tag_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("tag_id", tag_id);
        
        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumno_tag_eliminar',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function getTutoriasIndividualesGestion({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutorias_individuales_gestion',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getMetricasTicket({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/metricas_ticket',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
/* -------------------------------------------------------------------------- */
/*                               Get Alumnos hoy                              */
/* -------------------------------------------------------------------------- */
export async function getAlumnos_planificados_hoy({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_planificados_hoy',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnosPendientesPagoPrimeraCuota({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_pendientes_pago_primera_cuota',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
export async function getAlumnos_planificados_pasado({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_planificados_pasado',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
/* -------------------------------------------------------------------------- */
/*                           Get Alumnos esta semana                          */
/* -------------------------------------------------------------------------- */
// Planinficados
export async function getAlumnos_planificados_esta_semana({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_planificados_esta_semana',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
/* -------------------------------------------------------------------------- */
/*                           Get Alumnos esta semana                          */
/* -------------------------------------------------------------------------- */
export async function getAlumnos_contactados_hoy({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_contactados_hoy',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}

export async function getAvisosUsuario({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/avisos_usuario',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }
}
export async function getAvisosSeguimientoUsuario({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/avisos_seguimiento_usuario',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }
}
/* -------------------------------------------------------------------------- */
/*                           Get Alumnos esta semana                          */
/* -------------------------------------------------------------------------- */
export async function vdw_alumnos_estado({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/vdw_alumnos_estado',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
/* -------------------------------------------------------------------------- */
/*                           valumnos_productos_cuotas_prevision_meses                          */
/* -------------------------------------------------------------------------- */
export async function vAlumnosProductosCuotasPrevisionMeses({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/valumnos_productos_cuotas_prevision_meses',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}

export async function getAlumnosCoordinador({ usuario_id, coordinador_usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("coordinador_usuario_id", coordinador_usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/alumnos_coordinador',
            data: formData,
            headers: {
            },
         });

        return response.data;
    } catch (error) {
        return undefined;
    }
}
/* -------------------------------------------------------------------------- */
/*                           get_leads_by_comercial_post                          */
/* -------------------------------------------------------------------------- */
export async function get_leads_by_comercial({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/get_leads_by_comercial',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}

/* -------------------------------------------------------------------------- */
/*                               Get Alumno                                */
/* -------------------------------------------------------------------------- */
export async function getAlumno({ alumno_id, usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json'
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //             alumno_id
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}

/* -------------------------------------------------------------------------- */
/*                            Actualizar alumno                               */
/* -------------------------------------------------------------------------- */
export async function update_alumno({ usuario_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_desde", dateToMysql(campos.alumno_desde))
        formData.append("alumno_id", campos.alumno_id)
        formData.append("comercial_id", campos.comercial_id)
        formData.append("como_conocido_id", campos.como_conocido_id)
        formData.append("comunidad_autonoma_id", campos.comunidad_autonoma_id)
        formData.append("correo_electronico", campos.correo_electronico)
        formData.append("es_demo", campos.es_demo)
        formData.append("especialidad_id", campos.especialidad_id)
        formData.append("estado_id", campos.estado_id)
        formData.append("fecha_alta", campos.fecha_alta)
        formData.append("nombre", campos.nombre)
        formData.append("formulario_observaciones", campos.formulario_observaciones)
        formData.append("oposicion_id", campos.oposicion_id)
        formData.append("proximo_contacto", dateToMysql(campos.proximo_contacto))
        formData.append("situacion_id", campos.situacion_id)
        formData.append("telefono", campos.telefono)
        formData.append("temperatura_id", campos.temperatura_id)
        formData.append("tutor", campos.tutor)
        formData.append("ultimo_contacto", dateToMysql(campos.ultimo_contacto))
        formData.append("fecha_baja", dateToMysql(campos.fecha_baja))
        formData.append("dni", campos.dni)
        formData.append("direccion", campos.direccion)
        formData.append("codigo_postal", campos.codigo_postal)
        formData.append("ciudad", campos.ciudad)
        formData.append("provincia", campos.provincia)
        formData.append("pais", campos.pais)
        formData.append("tutor_preferido_id", campos.tutor_preferido_id)
        formData.append("fecha_posible_baja", dateToMysql(campos.fecha_posible_baja))
        formData.append("motivo_baja", campos.motivo_baja)
        formData.append("motivo_baja_id", campos.motivo_baja_id)
        formData.append("importe_a_cuenta", campos.importe_a_cuenta)
        formData.append("a_cuenta_toda_preparacion", campos.a_cuenta_toda_preparacion)
        formData.append("es_baja_tutea", campos.es_baja_tutea)
        formData.append("es_baja_portal_alumno", campos.es_baja_portal_alumno)
        formData.append("num_convocatorias", campos.num_convocatorias)
        formData.append("ultima_convocatoria_year", campos.ultima_convocatoria_year)
        formData.append("fecha_nacimiento", dateToMysql(campos.fecha_nacimiento))
        formData.append("horas_estudio", campos.horas_estudio)
        formData.append("motivo_perdida_id", campos.motivo_perdida_id)
        formData.append("dedicacion_id", campos.dedicacion_id)

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_alumno',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                              Insertar alumno                               */
/* -------------------------------------------------------------------------- */
export async function insertar_alumno({ usuario_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_desde", dateToMysql(campos.alumno_desde))
        formData.append("comercial_id", campos.comercial_id)
        formData.append("como_conocido_id", campos.como_conocido_id)
        formData.append("comunidad_autonoma_id", campos.comunidad_autonoma_id)
        formData.append("correo_electronico", campos.correo_electronico)
        formData.append("es_demo", campos.es_demo)
        formData.append("especialidad_id", campos.especialidad_id)
        formData.append("estado_id", campos.estado_id)
        formData.append("fecha_alta", campos.fecha_alta)
        formData.append("nombre", campos.nombre)
        formData.append("formulario_observaciones", campos.formulario_observaciones)
        formData.append("oposicion_id", campos.oposicion_id)
        formData.append("proximo_contacto", dateToMysql(campos.proximo_contacto))
        formData.append("situacion_id", campos.situacion_id)
        formData.append("telefono", campos.telefono)
        formData.append("temperatura_id", campos.temperatura_id)
        formData.append("tutor", campos.tutor)
        formData.append("ultimo_contacto", dateToMysql(campos.ultimo_contacto))
        formData.append("dni", campos.dni)
        formData.append("direccion", campos.direccion)
        formData.append("codigo_postal", campos.codigo_postal)
        formData.append("ciudad", campos.ciudad)
        formData.append("provincia", campos.provincia)
        formData.append("pais", campos.pais)
        formData.append("motivo_baja", campos.motivo_baja)
        formData.append("motivo_baja_id", campos.motivo_baja_id)
        formData.append("importe_a_cuenta", campos.importe_a_cuenta)
        formData.append("a_cuenta_toda_preparacion", campos.a_cuenta_toda_preparacion)
        formData.append("es_baja_tutea", campos.es_baja_tutea)
        formData.append("es_baja_portal_alumno", campos.es_baja_portal_alumno)
        formData.append("num_convocatorias", campos.num_convocatorias)
        formData.append("ultima_convocatoria_year", campos.ultima_convocatoria_year)
        formData.append("fecha_nacimiento", dateToMysql(campos.fecha_nacimiento))
        formData.append("horas_estudio", campos.horas_estudio)
        formData.append("motivo_perdida_id", campos.motivo_perdida_id)
        formData.append("dedicacion_id", campos.dedicacion_id)

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insertar_alumno',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export async function insertar_usuario_creador({ usuario_id, campos }) {
    try {
        let formData = new FormData();

        console.log(campos)
        formData.append("nombre", campos.nombre);
        formData.append("apellidos", campos.apellidos);
        formData.append("correo_electronico", campos.correo_electronico);
        formData.append("telefono", campos.telefono);
        formData.append("dni", campos.dni);
        formData.append("direccion", campos.direccion);
        formData.append("provincia", campos.provincia);
        formData.append("num_cuenta", campos.num_cuenta);
        formData.append("correo_electronico_personal", campos.correo_electronico_personal);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insertar_usuario_creador',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_usuario_creador({ usuario_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("creador_id", campos.creador_id);
        formData.append("nombre", campos.nombre);
        formData.append("apellidos", campos.apellidos);
        formData.append("correo_electronico", campos.correo_electronico);
        formData.append("telefono", campos.telefono);
        formData.append("dni", campos.dni);
        formData.append("direccion", campos.direccion);
        formData.append("provincia", campos.provincia);
        formData.append("num_cuenta", campos.num_cuenta);
        formData.append("correo_electronico_personal", campos.correo_electronico_personal);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_usuario_creador',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        // return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function insertar_usuario_factura({ usuario_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", campos.usuario_id);
        formData.append("concepto", campos.concepto);
        formData.append("cantidad", campos.cantidad);
        formData.append("precio", campos.precio);
        formData.append("fecha", dateToMysql(campos.fecha));
        formData.append("detalle", campos.detalle);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insertar_usuario_factura',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function delete_usuario_factura({ usuario_id, factura_id }) {
    try {
        let formData = new FormData();

        formData.append("factura_id", factura_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/delete_usuario_factura',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_usuario_factura({ usuario_id, campos }) {
    try {
        let formData = new FormData();

        formData.append("factura_id", campos.factura_id);
        formData.append("usuario_id", campos.usuario_id);
        formData.append("concepto", campos.concepto);
        formData.append("cantidad", campos.cantidad);
        formData.append("precio", campos.precio);
        formData.append("fecha", dateToMysql(campos.fecha));
        formData.append("detalle", campos.detalle);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_usuario_factura',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                              Update comercial                              */
/* -------------------------------------------------------------------------- */
export async function update_comercial({ usuario_id, alumno_id, comercial_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("comercial_id", comercial_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/actualizar_comercial',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
/* -------------------------------------------------------------------------- */
/*                              Update Estado                                 */
/* -------------------------------------------------------------------------- */
export async function insert_update_alumno_estado({ usuario_id, alumno_id, estado_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("estado_id", estado_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insert_update_alumno_estado',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export async function alumno_baja({ usuario_id, alumno_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/alumno_baja',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}