import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { getAlumnos, getAlumnosBaja, getAlumnosRegistrados, getAlumnosTuteaLastLogin, update_comercial } from '../../../services/alumnos';
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { BASE_URL_SERVICE, BASE_URL_SERVICE_PROCESOS } from '../../../constants';
import { seguimientoTutoreslList } from '../../../services/tutores';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
}));

export default function SeguimientoTutoresContent() {
    const classes = useStyles();

    const [tutores, setTutores] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()


    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando tutores")

        const usuario_id = usuarioSeleccionado.id

        seguimientoTutoreslList({ usuario_id }).then((response) => {

            response && setTutores(response.tutores)
            setLoading(false)

        });

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id', hide: false, headerName: 'ID' },
      { field: 'tutor', hide: false, headerName: 'Tutor', width: 250 },
      { field: 'num_entregas', headerName: 'Entregas', minWidth: 150, flex: 1 },
      { field: 'num_simulacros', headerName: 'Simulacros', minWidth: 150, flex: 1 },
      { field: 'num_dudas', headerName: 'Dudas', minWidth: 150, flex: 1 },
      { field: 'num_tutorias', headerName: 'Tutorias', minWidth: 150, flex: 1 },
      { field: 'num_alumnos', headerName: 'Alumnos', minWidth: 150, flex: 1 },
    //   // {
    //   //   field: 'actions',
    //   //   type: 'actions',
    //   //   width: 80,
    //   //   getActions: (params) => [
    //   //     <GridActionsCellItem
    //   //       icon={<EditIcon />}
    //   //       label="Modificar"
    //   //       component={Link}
    //   //       to={`alumnos/detalle/${params.id}`}
    //   //       showInMenu
    //   //     />,
    //   //   ],
    //   // }
    ]

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
      }
      
      QuickSearchToolbar.propTypes = {
        clearSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
      };

    const Contenido = () => {
        const [rows, setRows] = React.useState(tutores);
        const [searchText, setSearchText] = React.useState('');
        const requestSearch = (searchValue) => {
          // console.log(searchValue)
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
          const filteredRows = tutores.filter((row) => {
              return Object.keys(row).some((field) => {
                  // console.log(field)
                  // console.log(row)
                  return searchRegex.test(row[field]);
              });
          });
          setRows(filteredRows);
        };

        // const handleAlumnoDetalle = (row) => {
        //   console.log(row)
        //   history.push('alumnos/detalle/' + row.alumno_id )
        // }
        // const [anchorElOpciones, setAnchorElOpciones] = React.useState(null);
        // const open = Boolean(anchorElOpciones);
      
        // const handleClickOpciones = (event) => {
        //   setAnchorElOpciones(event.currentTarget);
        // };
      
        // const handleCloseOpciones = () => {
        //   setAnchorElOpciones(null);
        // };

        // const handleExportarTelefonos = () => {
        //   setAnchorElOpciones(null);
        // }
        return (
          <>
              
            <Box mt={12} mb={3} mx={2}>
              <Typography variant="h5" component="h3" gutterBottom display='inline' style={{fontWeight: 500}} >
                  Seguimiento de tutores
              </Typography>
            </Box>

            <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                <DataGrid 
                    rows={rows} 
                    columns={columnas} 
                    rowHeight={30}
                    components={{ Toolbar: QuickSearchToolbar }}
                    // onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.row) }
                    componentsProps={{
                        toolbar: {
                          value: searchText,
                          onChange: (event) => requestSearch(event.target.value),
                          clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div> *

                </>
                
                // :
                // <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
