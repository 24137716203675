import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'

/* -------------------------------------------------------------------------- */
/*                               Get Tutores                              */
/* -------------------------------------------------------------------------- */
export async function getTutores({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        // formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'tutores',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function getTutoresPeriodosLiquidacion({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        // formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/get_tutores_periodos_liquidacion',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function tutorRealizarLiquidaciones({ usuario_id, tutoresSeleccionados, periodosSeleccionados, numSesionesGrupales }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("tutores_seleccionados", tutoresSeleccionados);
        formData.append("periodos_seleccionados", periodosSeleccionados);
        formData.append("num_sesiones_grupales", numSesionesGrupales);
        // formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutor_realizar_liquidaciones',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function tutorEnviarLiquidacionCorreoElectronico({ usuario_id, liquidacion_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("liquidacion_id", liquidacion_id);
        // formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutor_enviar_liquidacion_correo_electronico',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function getLiquidacionTutores({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        // formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/liquidacion_tutores',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function liquidacionTutoresUpdateCampoValor({ usuario_id, liquidacion_id, campo, valor }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("liquidacion_id", liquidacion_id);
        formData.append("campo", campo);
        formData.append("valor", valor);
        // formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/tutor_liquidaciones_update_campo_valor',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function facturaUsuarioUpdateCampoValor({ usuario_id, factura_id, campo, valor }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("factura_id", factura_id);
        formData.append("campo", campo);
        formData.append("valor", valor);
        // formData.append("alumno_id", alumno_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/usuario_factura_update_campo_valor',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function seguimientoTutoreslList({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/seguimiento_tutores_list',
            data: formData,
            headers: {
             // 'Content-Type': 'multipart/form-data',
             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
             // 'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}